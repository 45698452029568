<template>
  <div class="KnowledgeLibraryManageForm-wrapper">
    <form-panel
      ref="form"
      v-bind="config"
      :form="form"
      :submitSuccess="submitSuccess"
      :submitBefore="submitBefore"
      @update="update"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail v-loading="pageLoading">
        <col2-block>
          <el-form-item label="标题" prop="fileName"
            :rules="[ { required: true, message: '请输入标题', trigger: ['blur', 'change'] } ]"
          >
            <v-input v-model="form.fileName" placeholder="请输入标题" :maxlength="20" :width="width"/>
          </el-form-item>
          <el-form-item label="发布端口" prop="platform"
            :rules="[ { required: true, validator: validatePort, trigger: ['blur','change'] } ]"
          >
            <div class="check-panel">
              <v-checkbox
                class="marginRight"
                v-model="item.checked"
                v-for="(item, index) in portOps"
                :key="index"
                :index="index"
                type="default"
                :label="item.text"
              />
            </div>
          </el-form-item>
          <el-form-item label="选择层级" prop="documentId"
            :rules="[ { required: true, message: '请选择层级', trigger: 'change' } ]"
          >
            <select-tree
              :value.sync="form.documentId"
              :data.sync="treeSelectData"
              placeholder="查询所属部门"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
            />
          </el-form-item>
          <el-form-item label="搜索标签" prop="labelIdList"
            :rules="[ { required: true, validator: validateLabel, trigger: 'change' } ]">
            <el-tag class="marginRight" v-for="(labelItem, labelIndex) in labelList" :key="labelItem.labelName" closable @close="closeLabel(labelIndex)">
              {{ labelItem.labelName }}
            </el-tag>
            <v-button v-show="labelList.length < 3" text="新增标签" @click="showMultiSelect" />
          </el-form-item>
          <el-form-item label="内容" :rules="[{ required: true, message: '请输入内容', trigger: ['blur','change'] }]" prop="content">
            <v-ueditor v-model="form.content"></v-ueditor>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="搜索标签"
      :isShow.sync="isShow"
      :backFill.sync="labelList"
      :searchUrl="getLabelURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :beforeSave="beforeSave">
      <template #searchSlot>
        <v-input label="标签名称" v-model="searchParams.labelName"></v-input>
        <!-- <v-datepicker label="创建时间段" type="rangedatetimer" format="yyyy-MM-dd HH:mm" :startTime.sync="searchParams.startIntime" :endTime.sync="searchParams.endIntime" /> -->
        <v-datepicker
          type="rangedatetimer"
          label="创建日期"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.startIntime"
          :endTime.sync="searchParams.endIntime"
          :appendToBody="false"
        />
      </template>
    </multi-select>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tag } from 'element-ui'
import {
  addFileURL,
  getDetailURL,
  getLabelListURL,
  getEnterOrgTreeListURL
} from './api'
import {
  portOps,
  portMap
} from './map'
import {
  Col2Detail,
  Col2Block,
  SelectTree,
  MultiSelect
} from 'components/bussiness'
import { vCheckbox, vUeditor } from 'components/control/index'

Vue.use(Tag)

export default {
  name: 'memberInfoForm',
  components: {
    Col2Detail,
    Col2Block,
    SelectTree,
    MultiSelect,
    vCheckbox,
    vUeditor
  },
  data () {
    return {
      width: 520,
      pageLoading: false, // 页面加载..
      id: '',
      submitContentType: 'application/json;charset=UTF-8',
      config: {
        queryUrl: getDetailURL,
        submitUrl: addFileURL
      },
      form: {
        fileName: '',
        platform: '',
        labelIdList: [],
        documentId: '',
        content: ''
      },
      /* select-tree 控件 */
      documentId: '',
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        // isLeaf: (data, node) => {    // 必须
        //   return data.hasChild === 0
        // },
        btn: 'operateArr' // 必须
      },
      treeData: [],
      /* 多选框控件 */
      isShow: false,
      getLabelURL: getLabelListURL,
      headers: [
        {
          prop: 'labelName',
          label: '标签名称'
        },
        {
          prop: 'labelStatusName',
          label: '状态'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      searchParams: {
        labelName: '',
        startIntime: '',
        endIntime: ''
      },
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      responseKey: {
        id: 'id',
        name: 'labelName'
      },
      labelList: [],
      /* 辅助状态 */
      portOps: portOps(),
      portMap
    }
  },
  watch: {
    portOps: {
      handler (val) {
        this.form.platform = val.filter(v => v.checked).map(v => v.value).join(',')
      },
      deep: true,
      immediate: true
    },
    labelList: {
      handler (val) {
        this.form.labelIdList = val.map(v => v.id)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    const { id, documentId } = this.$route.query
    this.id = id
    if (documentId) {
      this.documentId = documentId
      this.form.documentId = documentId
    }
    this.$setBreadcrumb(id ? '编辑' : '新增')
  },
  mounted () {
    if (this.id) {
      this.$refs.form.getData({ id: this.id })
    }
    this.getTreeList()
  },
  methods: {
    goBack () {
      sessionStorage.setItem('documentId', this.documentId)
      this.$router.go(-1)
    },
    update (data) {
      this.form.id = data.id
      this.form.fileName = data.fileName
      this.portOps = this.portOps.map(v => ({
        ...v,
        checked: data.platform.split(',').includes(v.value.toString())
      }))
      this.labelList = data.labelList.map(v => ({
        id: v.labelId,
        labelName: v.labelName
      }))
      this.form.documentId = data.documentId
      console.log(this.labelList)
      this.form.content = data.content
    },
    submitBefore (data) {
      let result = true
      Object.keys(data).forEach(v => {
        if (data[v] === '') {
          result = false
        } else if (data[v] === undefined) {
          result = false
        } else if (Array.isArray(data[v]) && data[v].length === 0) {
          result = false
        }
      })
      return result
    },
    submitSuccess () {
      sessionStorage.setItem('documentId', this.form.documentId)
      return true
    },
    getTreeList () { // 树数据
      this.$axios.get(getEnterOrgTreeListURL)
        .then((res) => {
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        })
    },

    /* 其余控件方法 */
    closeLabel (labelIndex) {
      this.labelList.splice(labelIndex, 1)
    },
    showMultiSelect () {
      this.$refs.form.clearValidate('labelIdList')
      this.isShow = true
    },
    beforeSave (list, ids) {
      if (list && list.length > 3) {
        this.$message({
          type: 'error',
          message: '最多只能选择三个标签',
          center: true
        })
        return false
      }
      return true
    },

    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },

    /* 表单校验 */
    validatePort (rule, value, callback) {
      setTimeout(() => {
        if (this.form.platform === '') {
          callback(new Error('请选择发布端口'))
        } else {
          callback()
        }
      })
    },
    validateLabel (rule, value, callback) {
      setTimeout(() => {
        if (this.form.labelIdList.length === 0) {
          callback(new Error('请选择标签'))
        } else {
          callback()
        }
      })
    },

    /** 辅助类方法 */
    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.marginRight {
  margin-right: 20px;
}
.check-panel {
  display: flex;
}
</style>
