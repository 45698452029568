var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "KnowledgeLibraryManageForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              submitSuccess: _vm.submitSuccess,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                },
              ],
            },
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题",
                        prop: "fileName",
                        rules: [
                          {
                            required: true,
                            message: "请输入标题",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入标题",
                          maxlength: 20,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.fileName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fileName", $$v)
                          },
                          expression: "form.fileName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布端口",
                        prop: "platform",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validatePort,
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "check-panel" },
                        _vm._l(_vm.portOps, function (item, index) {
                          return _c("v-checkbox", {
                            key: index,
                            staticClass: "marginRight",
                            attrs: {
                              index: index,
                              type: "default",
                              label: item.text,
                            },
                            model: {
                              value: item.checked,
                              callback: function ($$v) {
                                _vm.$set(item, "checked", $$v)
                              },
                              expression: "item.checked",
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择层级",
                        prop: "documentId",
                        rules: [
                          {
                            required: true,
                            message: "请选择层级",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("select-tree", {
                        attrs: {
                          value: _vm.form.documentId,
                          data: _vm.treeSelectData,
                          placeholder: "查询所属部门",
                          treeData: _vm.treeData,
                          treeProps: _vm.treeProps,
                          highlightCurrent: true,
                          filterNodeMethod: _vm.filterNodeMethod,
                          "expand-on-click-node": false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "documentId", $event)
                          },
                          "update:data": function ($event) {
                            _vm.treeSelectData = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "搜索标签",
                        prop: "labelIdList",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validateLabel,
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _vm._l(_vm.labelList, function (labelItem, labelIndex) {
                        return _c(
                          "el-tag",
                          {
                            key: labelItem.labelName,
                            staticClass: "marginRight",
                            attrs: { closable: "" },
                            on: {
                              close: function ($event) {
                                return _vm.closeLabel(labelIndex)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(labelItem.labelName) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      _c("v-button", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.labelList.length < 3,
                            expression: "labelList.length < 3",
                          },
                        ],
                        attrs: { text: "新增标签" },
                        on: { click: _vm.showMultiSelect },
                      }),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "内容",
                        rules: [
                          {
                            required: true,
                            message: "请输入内容",
                            trigger: ["blur", "change"],
                          },
                        ],
                        prop: "content",
                      },
                    },
                    [
                      _c("v-ueditor", {
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "搜索标签",
          isShow: _vm.isShow,
          backFill: _vm.labelList,
          searchUrl: _vm.getLabelURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          beforeSave: _vm.beforeSave,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.labelList = $event
          },
          "update:back-fill": function ($event) {
            _vm.labelList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标签名称" },
                  model: {
                    value: _vm.searchParams.labelName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "labelName", $$v)
                    },
                    expression: "searchParams.labelName",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    type: "rangedatetimer",
                    label: "创建日期",
                    format: "YYYY-MM-DD HH:mm",
                    startTime: _vm.searchParams.startIntime,
                    endTime: _vm.searchParams.endIntime,
                    appendToBody: false,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startIntime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startIntime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endIntime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endIntime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }